import React from "react"
import { StaticQuery, graphql } from "gatsby"
import EventsList from "./eventsList"

const EventsListContainer = () => (
  <StaticQuery
    query={graphql`
      query EventsListQuery {
        allContentfulEvents {
          nodes {
            slug
            eventTitle
            eventTime
            eventDescription {
              eventDescription
            }
            eventCoverImage {
              fluid(maxWidth: 4000,quality:100) {
                src
              }
            }
            eventBannerImages {
              id
              fluid(maxWidth: 4000,quality:100) {
                src
              }
            }
          }
        }
      }
    `}
    render={data => {
      const events = data.allContentfulEvents.nodes
      return (
        <EventsList events={events} />
      )
    }}
  />
  
)

export default EventsListContainer
