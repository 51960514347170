import React, { Fragment } from "react"
import { Grid, Image, Pagination, Button, Icon } from "semantic-ui-react"
import { Link } from "gatsby"
import {  changeEventsPage } from "../../state/app"
import { connect } from "react-redux"

const initialState = {
  isLoading: false,
  activePage: 1
}

class EventsList extends React.Component {
  state = initialState

  componentDidMount() {
    this.setState({ activePage: this.props.products.eventsPage })
  }

  async handleChangePanigation(event, data) {
    this.props.changeEventsPage(data.activePage)
    await this.setState({
      activePage: data.activePage
    })
  }

  render() {
    const { activePage } = this.state
    const events = this.props.events.slice(activePage * 4 - 4, activePage * 4)

    return (
      <Fragment>
        <Grid divided="vertically" className="events-list">
          {events.map(event => {
            const link = `/events/${event.slug}`
            return (
              <Grid.Row columns={2} key={event.slug} className="events-item">
                <Grid.Column>
                  <Image
                    src={event.eventCoverImage.fluid.src}
                    wrapped
                    ui={false}
                  />
                </Grid.Column>
                <Grid.Column style={{ paddingRight: "14%" }}>
                  <h1>{event.eventTitle}</h1>
                  <h5>{event.eventTime}</h5>
                  <p>{event.eventDescription.eventDescription}</p>
                  <Link to={link} key={event.slug}>
                    <Button>DISCOVER MORE</Button>
                  </Link>
                </Grid.Column>
              </Grid.Row>
            )
          })}
        </Grid>
        <div className="paginationContainer">
          <Pagination
            boundaryRange={1}
            activePage={activePage}
            ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
            firstItem={null}
            lastItem={null}
            prevItem={{ content: <Icon name='angle left' />, icon: true }}
            nextItem={{ content: <Icon name='angle right' />, icon: true }}
            siblingRange={1}
            totalPages={Math.ceil(this.props.events.length / 4)}
            onPageChange={(event, data) =>
              this.handleChangePanigation(event, data)
            }
          />
        </div>
      </Fragment>
    )
  }
}

export default connect(
  ({ products }) => ({ products }),
  { changeEventsPage }
)(EventsList)
