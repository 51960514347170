import React, { Fragment } from "react"
import EventsBanner from "../components/events/eventsBanner"
import EventsListContainer from "../components/events/eventsListContainer"
import Footer from "../components/common/footer"
import "semantic-ui-css/semantic.min.css"
import "../styles/index.css"

const Events = () => {
  return (
    <Fragment>
      <EventsBanner/>
      <EventsListContainer />
      <Footer />
    </Fragment>
  )
}
export default Events
